<template>
    <div>
        <div style="margin-bottom: 20vw">
            <div class="check_title">———— 订单信息 ————
                <div style="font-size: 3.2vw;color: #ca1416;padding: 1vw 0">*注 可勾选多个订单进行核销</div>
            </div>
            <div class="box" v-for="(item, index) in order" :key="index" >
                <div v-if="item.order_status !=2 && item.order_status!=3">
                    <div v-if="item.order_type == 'package'">
                        <input type="checkbox" class="check_choice" disabled/>
                    </div>
                    <div v-else>
                        <input type="checkbox" class="check_choice"
                               :value="item.id"
                               v-model="checkVal"
                               @click="count()"
                        />
                    </div>
                </div>
                <div>
                    <div v-if="item.order_type == 'package'" style="font-size: 3.2vw;color: #ca1416">* 该产品为套票，不可勾选</div>
                    <div class="check_information">
                        <p>id：</p>
                        <p>{{item.id}}</p>
                    </div>
                    <div class="check_information">
                        <p>订单号：</p>
                        <p>{{item.orderID}}</p>
                    </div>
                    <div class="check_information">
                        <p>套餐名：</p>
                        <p>{{item.gooods_name}}</p>
                    </div>
                    <div class="check_information"  v-if="item.randomcode">
                        <p>核销码：</p>
                        <p>{{item.randomcode}}</p>
                    </div>
                    <div class="check_information">
                        <p>核销状态：</p>
                        <p style="color: #C20B09">{{orderStatusStr(2,item.order_status)}}</p>
                    </div>
                    <div class="check_information">
                        <p>类型：</p>
                        <p>{{item.typeName}}</p>
                    </div>
                    <div class="check_information">
                        <p>商户名：</p>
                        <p>{{item.business_name}}</p>
                    </div>
                    <div class="check_information">
                        <p>购买数量：</p>
                        <p>{{item.number}}</p>
                    </div>
                    <div class="check_information">
                        <p>购买时间：</p>
                        <p>{{item.create_time}}</p>
                    </div>
                    <div class="check_information" v-if="permission.is_payFee == '1'">
                        <p>支付金额：</p>
                        <p style="color: #C20B09" id="money">{{moneyFen2Yuan(item.total_fee)}}</p>
                    </div>
                </div>
            </div>
            <div v-if="order.length >= '10'" style="font-size: 3.2vw;color: #ca1416;padding: 0 5vw">* 最多展示10张个订单</div>
        </div>
        <div class="btn">
            <div class="btn-remark">
                <p>备注：</p>
                <input type="text" v-model="remark" placeholder="请输入备注" name="remark"/>
            </div>
            <div class="btn-box">
                <router-link class="container-div p_key" to="/check/index">返回主页</router-link>
                <p class="p_key" @click="check">确认核销 <span> * {{number}}</span></p>
            </div>
        </div>

    </div>
</template>

<script>
    import { moneyFen2Yuan, orderStatusStr } from '../../utils/tools'
    import { globalConfig } from '../../utils/mixin'
    export default {
        name: 'Check',
        mixins: [globalConfig],
        components: {},
        data () {
            return {
                id: '',
                sign: '',
                type: '',
                code: '',
                url: '',
                checkUrl: '',
                order: [],
                permission: [],
                checkVal: [],
                number: 1,
                remark: ''
            }
        },
        methods: {
            moneyFen2Yuan,
            orderStatusStr,
            count: function () {
                const that = this
                setTimeout(function () {
                    console.log(that.checkVal)
                    that.number = that.checkVal.length
                    that.$toast('您已勾选 ' + that.number + ' 个订单')
                }, 50)
            },
            init: function () {
                this.axios.post(this.apiUrl + this.url, {
                    id: this.id,
                    sign: this.sign,
                    code: this.code
                }).then((response) => {
                    const data = response.data
                    console.log(data)
                    if (data.status === 1) {
                        this.order = data.order
                        this.permission = data.permission
                        this.checkVal.push(data.id)
                    } else if (data.status === 2) {
                        this.$router.push('/check/check/qr/' + this.id + '/' + this.sign)
                    } else {
                        this.$router.push('/check/warning/' + data.msg)
                    }
                })
            },
            check: function () {
                if (this.checkVal.length == 0) {
                    this.$toast('请选择核销订单')
                } else {
                    console.log(this.checkVal)
                    const that = this
                    this.axios.post(this.apiUrl + this.checkUrl, {
                        id: this.id,
                        sign: this.sign,
                        checkVal: this.checkVal,
                        remark: this.remark
                    }).then((response) => {
                        console.log(response)
                        const data = response.data
                        if (data.status === 1) {
                            this.$toast({
                                message: data.msg,
                                onClose: function () {
                                    that.$router.push('/check/index')
                                }
                            })
                        } else {
                            this.$router.push('/check/warning/' + data.msg)
                        }
                    })
                }
            }
        },
        created () {
            this.type = this.$route.params.type
            this.id = this.$route.params.id
            this.sign = this.$route.params.sign
            this.url = 'checkh5/order/getOrderAllInfo'
            this.checkUrl = 'checkh5/order/checkAll'
            this.init()
        },
        mounted () {
        },
        computed: {
        }
    }
</script>

<style scoped>
    body{
        background: #f3f3f3;
    }
    .check_title{
        width: 100vw;
        text-align: center;
        padding: 6vw 0 3vw 0;
        font-size: 4vw;
        font-weight: 500;
        color: #C20B09;
    }
    .box {
        width: 90vw;
        display: flex;
        align-items: center;
        margin: 0 5vw 3vw 5vw;
        padding: 3vw 0;
        border: 1px solid #cbcbcb;
        background-color: white;
    }
    .check_choice{
        width: 4vw;
        height: 4vw;
        border-radius: 5vw;
        margin: 0 3vw;
        background-color: initial;
        cursor: default;
        appearance: checkbox;
        box-sizing: border-box;
        padding: initial;
        border: initial;
    }
    .check_information{
        padding: 1vw 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3.4vw;
        color: #666666;
    }
    .check_information_number p{
        width: 5vw;
        height: 5vw;
        text-align: center;
        line-height: 5vw;
        background-color: #eee;
        margin: 0 0.8vw;
    }
    .check_information p:nth-child(2){
        width: 54vw;
        margin-right: 3vw;
    }

    .btn{
        width: 100vw;
        position: fixed;
        bottom: 0;
        box-shadow: -6px -1px 2px 0 #bebebe;
        background: #ffffff;
    }
    .btn-box {
        padding: 4vw 0;
        width: 100vw;
        display: flex;
        flex-flow: row;
        justify-content: space-around;
    }
    .btn-remark{
        padding: 4vw 0 0 10vw;
        width: 100vw;
        display: flex;
        align-items: center;
        flex-flow: row;
        font-size: 3.2vw;
        color: #666;
    }
    .btn-remark input{
        width: 70vw;
        height: 6vw;
        border: 1px solid #999;
        border-radius: 1vw;
        text-indent: 2vw;
    }
    .p_key{
        border-radius: 0.5rem;
        background-color: #C20B09;
        color: white;
        text-align: center;
        font-size: 1rem;
        padding: 0.5rem 2rem;
    }

</style>
